#root {
  background: #dfe1e0;
  height: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* fonts */
@font-face {
  font-family: Hyperspace;
  src: url('./fonts/Hyperspace.otf');
}
@font-face {
  font-family: HyperspaceBold;
  src: url('./fonts/Hyperspace Bold.otf');
}
@font-face {
  font-family: HyperspaceItalic;
  src: url('./fonts/Hyperspace Italic.otf');
}
@font-face {
  font-family: HyperspaceBoldItalic;
  src: url('./fonts/Hyperspace Bold Italic.otf');
}
@font-face {
  font-family: PixelOperator8;
  src: url('./fonts/PixelOperator8.ttf');
}
@font-face {
  font-family: PixelOperator8Bold;
  src: url('./fonts/PixelOperator8-Bold.ttf');
}

@font-face {
  font-family: PixelCursive;
  src: url('./fonts/Cursive.ttf');
}

button {
  background: none;
  border: none;
}
