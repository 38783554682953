.to {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  font-size: 16px;
}

.to .scrim {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.33;
  z-index: 5;
  pointer-events: all;
  cursor: pointer;
}

.to .outer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 6;
  scrollbar-gutter: stable;
}

.to .content {
  margin: auto auto 0;
  color: white;
  background-color: black;
  max-width: 1600px;
  min-height: 33%;
}

.to .header-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: black;
}

.to .header {
  padding: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 2;
  text-align: center;
  h1,
  button {
    font-family: PixelOperator8Bold;
    font-size: 200%;
    margin: 16px 0;
    line-height: 2;
  }
  button {
    color: white;
    font-size: 200%;
    cursor: pointer;
  }
}

.to hr {
  background: white;
  border: 2px solid white;
  margin-bottom: 24px;
}

.to .message {
  padding: 16px 32px;
  background-color: black;
  ul {
    padding: 0 0 0 18px;
  }
  p, li {
    line-height: 2;
    margin: 12px;
    font-family: PixelOperator8;
    font-size: 137.5%;
    strong {
      font-family: PixelOperator8Bold;
    }
    &.warning,
    a:visited,
    a:link {
      color: #f9d347;
      &.email {
        color: magenta;
      }
    }
    .signature {
      font-family: PixelCursive;
      font-size: 250%;
      color: magenta;
    }
    &.smaller {
      font-size: 120%;
    }
    .title {
      text-decoration: underline;
      font-size: 130%;
    }
  }
}

.to.about .message {
  a:visited,
  a:link {
    color: #d90368;
  }
}

.to.work .message {
  a:visited,
  a:link {
    color: #04a777;
  }
}

.to.skills .message {
  a:visited,
  a:link {
    color: #53B3CB;
  }
}
// .to.hello .message {
//   .signature {
//     color: #a70434;
//   }
//   a:visited,
//   a:link {
//     color: #04a777;
//   }
// }

@media (max-width: 700px) {
  .to {
    font-size: 10px;
  }
  .to .message {
    padding: 12px;
    p .signature {
      font-size: 175%;
    }
  }

  .to .content {
    max-width: 100%;
  }

  .to .header {
    h1 {
      margin-top: 42px;
      text-align: center;
    }
    button {
      position: absolute;
      top: 0px;
      right: 16px;
      font-size: 250%;
    }
  }
}
