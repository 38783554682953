.progress {
  position: absolute;
  margin: 0;
  top: 8px;
  right: 8px;
  font-family: Hyperspace;
  font-size: 60px;
  &.mobile {
    font-size: 32px;
  }
  &.text {
    left: 12px;
    right: inherit;
  }
}
